import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, ListGroup } from "react-bootstrap";
import Searchreporticon from "../../Images/img/Searchreporticon.png";
import playSmallbtnicn from "../../Images/img/playSmallbtnicn.png";
import { IRecentReports } from "../Interfaces/Interfaces";
import { Constants } from "../helpers/Constants";
import Loading from "../../Images/Loading.gif";
import axios from "axios";
import SearchBar from "./SearchBar";
import Searchicon from "../../Images/img/Searchicon.png";
import protectedAxiosInstance from "../helpers/api";
import { useNavigate } from "react-router-dom";
const env: string = (window.location.host.includes("dev") || window.location.host.includes("localhost")) ? "dev" : "non-dev";

interface props {
  recentReports: IRecentReports[];
}

interface report {
  TileText: string;
  Description: string;
  ReportURL: string;
  IsPowerBI: string;
  ContactEmail: string;
  Persona: string;
}

const UnifiedComponent: React.FC<props> = ({ recentReports }) => {
  const [reports, setReports] = useState<report[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await protectedAxiosInstance.get(
          `${window.location.origin}/api/Service/GetReportLibraryConfiguration`,
          {
            withCredentials: true,
          }
        );
        setReports(response.data.Table);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container fluid className="unified-component">
      <Row className="align-items-start">
        {/* Search Reports Section */}
        <Col md={6} className="search-reports" style={{ marginLeft: "-2px" }}>
          <div
            style={{
              height: "315px",
              display: "flex",
              flexDirection: "column",
            }}
            >
            {env === "dev" && (<>
            <h5 style={{ marginBottom: "0.6rem" }}>
              <img
                src={Searchreporticon}
                alt="Bar Chart Icon"
                className="icon"
                style={{height:"25px", width:"25px"}}
              />{" "}
              Search Reports
            </h5>
              <div style={{ position: "relative" }}>
              <SearchBar reportCatalog={reports} />
              <img
                src={Searchicon}
                alt="Search Icon"
                style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                }}
              />
              </div>
            </>)}
            <h6 style={env !== "dev" ? { marginTop: "0rem" } : { marginTop: "1rem" }}>
            {env !== "dev" && (
              <img
                  src={Searchreporticon}
                  alt="Bar Chart Icon"
                  className="icon"
                  style={env !== "dev" ? { display: "inline" }: {}}
              />)}
              Recently Viewed Reports
            </h6>
            <div className="grid-container">
              {recentReports.length > 0 ? (
                recentReports.map((view, index) =>
                  view.tileText !== "" ? (
                    <div key={index} className="grid-item">
                      <a
                        onClick={() => {
                          navigate(`/view?parent=${view.tileID}`);
                        }}
                        className="no-color-change"
                      >
                        {view.tileText}
                      </a>
                    </div>
                  ) : null
                )
              ) : (
                <p>This list is empty. Use the left menu to open a report.</p>
              )}
            </div>

            {env === "dev" && (
              <a
              className="view-report-catalog"
              onClick={() => {
                navigate("/view?parent=ReportCatalog_SearchReport");
              }}
              title="View report catalog"
              >
              <b>View report catalog &gt;</b>
              </a>
            )}
          </div>
        </Col>

        {/* Demo Section */}
       <Col md={6} className="demo">
            <div>
            <h5>
              <img
              src={playSmallbtnicn}
              alt="Play Icon"
              className="play-icon"
              style={{height:"25px", width:"25px"}}
              />
              New FastTrack BI Portal Demo
            </h5>
            <div
              className="demo-video bg-light d-flex justify-content-center align-items-center"
              style={{ height: "284px" }}
            >
              <iframe
              src="https://microsoft.sharepoint.com/sites/FastTrackProductTeam/_layouts/15/embed.aspx?UniqueId=0f495841-3f98-48a4-a492-4a35203c3714&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
              width="460"
              height="260"
              allowFullScreen
              title="FTBI Portal Demo.mp4"
              onError={() => {
                console.log("Something went wrong");
              }}
              ></iframe>
            </div>
            </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UnifiedComponent;
